import React from 'react';
import './App.css';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Signin from './pages'
import ReactGA from 'react-ga';

const TRACKING_ID = "UA-57835577-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>} exact />
        <Route path='/signin' element={<Signin/>} exact />

      </Routes>
    </Router>
  
      
  );
}

export default App;
