import React from 'react'
import { Button } from '../ButtonElement'
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, BtnWrap} from '../InfoSection/InfoElements'
import {ContactWrapper,ContactHeading} from '../Contact/ContactElements'
import ContactForm from "../../ContactForm";

const Contact2 = ({lightBg, id, imgStart, topLine, lightText, headline, darkText, description, link, buttonLabel, img, alt, primary, dark, dark2}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>  
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>Get In Touch</TopLine>
                                <Heading lightText={lightText}>Contact</Heading>
                                <Subtitle darkText={darkText}>Sending us an email is extremely easy. Fill out our contact form and a member of the Search And be Found team will be with you soon.</Subtitle>
                                <BtnWrap>
                                    <Button to={link}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact="true"
                                    offset={-80}
                                    primary={primary ? 1 : 0}
                                    dark={dark ? 1 : 0}
                                    dark2={dark2 ? 1 :0}
                                    >{buttonLabel}</Button>
                                </BtnWrap>
                            </TextWrapper>
                            
                        </Column1>
                        <Column2>
                        <ContactWrapper id="contact">
                            <ContactHeading>
                            Send Us a Message!
                            </ContactHeading>
                            <ContactForm />
                        </ContactWrapper>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default Contact2
