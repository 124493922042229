import React from 'react'
import {FooterContainer, FooterWrap, FooterLinksContainer, SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons, SocialIconLink, FooterLink, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle} from './FooterElements'
import { FaFacebook, FaInstagram, FaGoogle} from 'react-icons/fa'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Examples of Work</FooterLinkTitle>
                                <FooterLink href="//carolinahealthspan.com" target="_blank">CarolinaHealthSpan.com</FooterLink>
                                <FooterLink href="//coloradotrouthunters.com" target="_blank">ColoradoTroutHunters.com</FooterLink>
                                <FooterLink href="//righthandindustrial.com" target="_blank">RightHandIndustrial.com</FooterLink>
                                <FooterLink href="//boggsgroup.com" target="_blank">Boggs Construction</FooterLink>
                                <FooterLink>ActiveFlyFishing.com</FooterLink>
                        </FooterLinkItems>     
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Peoples Enterprises</FooterLinkTitle>
                                <FooterLink href="//thepeoplesclubs.com" target="_blank">ThePeoplesClubs.com</FooterLink>
                                <FooterLink href="//tourstockputters.com" target="_blank">TourStockPutters.com</FooterLink>
                                <FooterLink href="//peoples.golf" target="_blank">Peoples.Golf</FooterLink>
                                <FooterLink href="//thepeoplesproperties.com" target="_blank">Peoples Properties</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>SABF Content Network</FooterLinkTitle>
                                <FooterLink href="//charlottecarshows.com" target="_blank">CharlotteCarShows.com</FooterLink>
                                <FooterLink href="//raleighcarshows.com" target="_blank">RaleighCarShows.com</FooterLink>
                                <FooterLink>AtlantaCarShows.com</FooterLink>
                                <FooterLink>CarolinaDreamHouse.com</FooterLink>
                                
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                    <FooterLinksWrapper>
                        <FooterLinkItems>
                            <FooterLinkTitle>Recommended Products</FooterLinkTitle>
                                <FooterLink href="//lastpass.com" target="_blank">Last Pass</FooterLink>
                                <FooterLink href="//code.visualstudio.com" target="_blank">Visual Studio Code</FooterLink>
                                <FooterLink href="//aws.amazon.com" target="_blank">Cloud Services - AWS</FooterLink>
                                <FooterLink href="//freecodecamp.org" target="_blank">freeCodeCamp</FooterLink>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'>
                            SABF.
                        </SocialLogo>
                        <WebsiteRights>Search And Be Found, LLC © {new Date().getFullYear()} All rights reserved. </WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//facebook.com/searchandbefound" target="_blank" aria-label="Facebook">
                                <FaFacebook/>
                            </SocialIconLink>
                            <SocialIconLink href="//instagram.com/charlottecarshows/" target="_blank" aria-label="Instagram">
                                <FaInstagram/>
                            </SocialIconLink>
                            <SocialIconLink href="//g.page/r/Cc8EGvmP8lVGEAE" target="_blank" aria-label="Google">
                                <FaGoogle/>
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
