import React from 'react'
import Icon5 from '../../images/svg5.svg';
import Icon6 from '../../images/svg6.svg';
import Icon7 from '../../images/svg7.svg';
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP} from './ServicesElements'

const Services = () => {
    return (
            <ServicesContainer id="services">
                <ServicesH1>Our Services</ServicesH1>
                <ServicesWrapper>
                    <ServicesCard>
                        <ServicesIcon src={Icon5}/>
                        <ServicesH2>Consulting</ServicesH2>
                        <ServicesP>We can accelerate your presence online using FREE tools with the RIGHT configuration. .</ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon6}/>
                        <ServicesH2>Development</ServicesH2>
                        <ServicesP>From email campaigns to custom applications, our HTML/CSS/Javascript background works.</ServicesP>
                    </ServicesCard>
                    <ServicesCard>
                        <ServicesIcon src={Icon7}/>
                        <ServicesH2>Hosting</ServicesH2>
                        <ServicesP>GoDaddy Pro, Amazon EC2, Amazon LightSail, Cloudways - Linux Based (Ubuntu LTS) - Secure</ServicesP>
                    </ServicesCard>
                </ServicesWrapper>
            </ServicesContainer>
    )
}

export default Services
