export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Hosting & Development',
    headline: 'Your IT Guy!',
    description: "Kevin and his team have over 35 years of experience managing WordPress and Magento websites. We offer web site hosting, development services and marketing support. What can we help you with?",
    link: 'signup',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require("../../images/svg1.svg").default,
    alt: 'What are your needs?',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Services Offered',
    headline: 'Custom Built',
    description: 'After discussing your needs, we will put together a specific plan for your business. Some of the products we work on are: Magento 1 & 2, Custom React Apps, Linux Server Administration, Cloudways, Amazon EC2 & Lightsail Implementations',
    link: 'signup',
    buttonLabel: 'Contact',
    imgStart: false,
    img: require("../../images/svg2.svg").default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: true,
};

// export const homeObjThree = {
//     id: 'signup',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Get In Touch',
//     headline: 'Contact',
//     description: 'Email is our preferred method of communication. Send us an email today using the form below!',
//     link: 'contact',
//     buttonLabel: 'Contact Form >',
//     imgStart: false,
//     img: require("../../images/svg3.svg").default,
//     alt: 'Car',
//     dark: false,
//     primary: false,
//     darkText: true,
// };