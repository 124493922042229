import styled from 'styled-components'

export const ContactWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 450px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`


export const ContactHeading = styled.h1`
    margin-top: 30px;
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.1;
    font-weight: 600;
    justify-content:center;
    color: black;
    
    @media screen and (max-width:480px) {
        font-size: 18px;
    }
    
`