import React, {useState} from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data'
import Services from '../components/Services';
import Footer from '../components/Footer';
import DocumentMeta from 'react-document-meta';
import Contact2 from '../components/Contact2';
import { Contact123 } from '../components/Contact2/data';

// This is to open and close mobile navigation.
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    
    const meta = {
        title: 'Search And Be Found - Charlotte, NC - Online Marketing Agency',
        description: 'Offering Hosting and Development services, Search And Be Found has the experience to help you get found online today!',
        canonical: 'https://searchandbefound.com',
        meta: {
            charset: 'utf-8',
            name: { keywords: 'marketing, hosting, websites, development'}
            }
        };   

    return (
        <>
            <DocumentMeta {...meta} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...homeObjOne} />
            <InfoSection {...homeObjTwo} />
            <Services />
            <Contact2 {...Contact123} />
            {/* <InfoSection {...homeObjThree} /> */}
            {/* <Contact /> */}
            <Footer/>
        </>
    );
};

export default Home;
