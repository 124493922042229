export const Contact123 = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Get In Touch',
    headline: 'Contact',
    description: 'Email is our preferred method of communication. Send us an email today using the form below!',
    link: 'contact',
    buttonLabel: 'Get Started Today ',
    dark: false,
    primary: false,
    darkText: true,
};